<template>
  <div>
    <b-alert variant="danger" v-if="error.length > 0" show>
      <h4 class="alert-heading">Error obteniendo datos</h4>
      <div class="alert-body">
        Ocurrió un error al obtener bines {{ error }}
      </div>
    </b-alert>
    <div v-else-if="loading && !rows" class="loader">
      <b-spinner />
    </div>
    <b-card v-else no-body>
      <b-card-header>
        <b-card-title>Bines</b-card-title>
        <crear-modal :bin="selectedBin" :bines="rows" :callback="getBines" />
      </b-card-header>
      <b-card-body>
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Buscar</label>
              <b-form-input type="text" class="d-inline-block" @input="onSearch" placeholder="Buscar" />
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table mode="" :rows="rows" :columns="columns" :totalRows="totalRecords" :isLoading.sync="loading"
          @on-search="onSearchTable" @on-page-change="onPageChange" @on-per-page-change="onPerPageChange" :sort-options="{
            enabled: false,
          }" :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }" :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }">
          <template slot="emptystate">No hay bines para mostrar</template>

          <template slot="table-row" slot-scope="props">
            <!-- Column: Fecha -->
            <span v-if="props.column.field === 'fechacreacion'">
              <p class="mb-0">{{ formattedDate(props.row.fechacreacion) }}</p>
              <p class="mb-0">{{ formattedHour(props.row.fechacreacion) }}</p>
            </span>
            <!-- Column: Estado -->
            <span v-else-if="props.column.field === 'biActivo'">
              <b-badge :variant="statusVariant(props.row.biActivo)">
                {{ statusText(props.row.biActivo) }}
              </b-badge>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                  <template v-slot:button-content>
                    <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                  </template>
                  <b-dropdown-item @click="editBin($event, props.row)">
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>Editar</span>
                  </b-dropdown-item>
                  <!--  <b-dropdown-item @click="confirmDeleteBin($event, props.row)">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Eliminar</span>
                  </b-dropdown-item> -->
                </b-dropdown>
              </span>
            </span>

            <!-- Column: Otras Columnas -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Cantidad de bines </span>
                <b-form-select v-model="pageLength" :options="['10', '15', '20']" class="mx-1" @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                " />
              </div>
              <div>
                <b-pagination align="right" class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })" :value="1" :per-page="pageLength"
                  last-number :total-rows="props.total" first-number prev-class="prev-item" next-class="next-item">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card-body>

      <b-modal title="Error" ok-only v-model="showErrorModal" centered ok-title="Accept" ok-variant="danger"
        modal-class="modal-danger">
        <b-card-text>
          {{ error }}
        </b-card-text>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BAlert,
  BBadge,
  BAvatar,
  BSpinner,
  BCardBody,
  BDropdown,
  BCardText,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BCardHeader,
  BDropdownItem,
} from "bootstrap-vue";
import moment from "moment";
import CrearModal from "./CrearEditarBin.vue";
import { getAuthToken } from "@/auth/utils";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAlert,
    BBadge,
    BAvatar,
    BSpinner,
    BCardBody,
    BDropdown,
    BCardText,
    CrearModal,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BPagination,
    BFormSelect,
    BCardHeader,
    VueGoodTable,
    BDropdownItem,
    ToastificationContent,
  },
  data() {
    return {
      page: 1,
      rows: [],
      error: "",
      loading: true,
      searchTerm: "",
      pageLength: 10,
      selectedBin: {},
      totalRecords: 0,
      seachTimeout: null,
      openEditModal: false,
      showErrorModal: false,
      columns: [
        {
          label: "Bin",
          field: "biBin",
        },
        {
          label: "Tarjeta",
          field: "biDescripcion",
        },
        {
          label: "Banco",
          field: "biBanco",
        },
        {
          label: "Comisión",
          field: "biComision",
        },
        {
          label: "Estado",
          field: "biActivo",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
    };
  },
  mounted() {
    this.getBines();
  },
  computed: {
    statusText() {
      const status = {
        true: "Activo",
        false: "Inactivo",
      };
      return (s) => status[s];
    },
    statusVariant() {
      const statusColor = {
        false: "light-warning",
        true: "light-success",
      };
      return (s) => statusColor[s];
    },
  },
  methods: {
    onSearch(query) {
      //this.loading = true;
      this.searchTerm = query;
      /* clearTimeout(this.seachTimeout);
      this.seachTimeout = setTimeout(this.getBines(), 2500); 
      */
    },
    onSearchTable(params) {
      this.totalRecords = params.rowCount;
    },
    onPageChange(params) {
      this.page = params.currentPage;
      //this.getBines();
    },
    onPerPageChange(params) {
      this.pageLength = params.currentPerPage;
      //this.getBines();
    },
    onNewOrder(order) {
      this.rows.unshift(order);
    },
    formattedDate(originalDate) {
      return moment(new Date(originalDate)).format("DD/MM/YYYY");
    },
    formattedHour(originalDate) {
      return moment(originalDate).format("hh:mm a");
    },
    editBin(event, bin) {
      event && event.preventDefault();
      setTimeout(() => {
        this.selectedBin = {};
        this.$nextTick(() => {
          this.selectedBin = JSON.parse(JSON.stringify(bin));
        });
      }, 5);
    },
    confirmDeleteBin(event, bin) {
      event && event.preventDefault();
      this.$bvModal
        .msgBoxConfirm("Desea eliminar el Bin?.", {
          size: "sm",
          title: "Confirmación",
          okTitle: "Eliminar",
          centered: true,
          okVariant: "danger",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) this.deletePromo(bin);
        });
    },
    getBines() {
      this.loading = true;
      this.$http
        .post(this.$store.state.app.UrlMiddlewareGET, {
          path: "/bine/obtenertodos",
          body: JSON.stringify({ tokenSesion: getAuthToken() }),
        })
        .then((res) => {
          this.loading = false;
          if (res.status === 200) {
            this.rows = res.data;
            this.totalRecords = this.rows.length;
          } else {
            this.error = res.data.mensaje;
            this.showErrorModal = true;
          }
        })
        .catch((e) => {
          this.loading = false;
          this.error = e.message;
          this.showErrorModal = true;
        });
    },
  },
};
</script>

<style lang="scss">
table.vgt-table {
  font-size: 13px !important;
}

.loader {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
