<template>
  <div>
    <b-button @click="onclickCrear" variant="primary"> Crear </b-button>
    <b-modal
      id="modal-crear-bin"
      :title="titleModal"
      v-model="show"
      centered
      hide-footer
      no-close-on-backdrop
    >
      <b-form-group>
        <b-form-checkbox v-model="mBin.biActivo" switch>
          Activo
        </b-form-checkbox>
      </b-form-group>
      <b-form-group label="Bin" label-for="v-bin">
        <b-form-input
          id="v-bin"
          step="1"
          type="number"
          v-model="mBin.biBin"
          :class="[error.mBin.bin ? 'is-invalid' : '']"
          placeholder="Bin"
        />
        <div v-if="error.mBin.bin" class="invalid-feedback">
          Ingrese Bin
        </div>
      </b-form-group>
      <b-form-group>
        <label for="v-banco">Banco</label>
        <v-select
          id="v-banco"
          label="title"
          v-model="mBin.biBanco"
          taggable
          :options="bancos"
          push-tags
          :clearable="false"
          :class="[error.mBin.banco ? 'is-invalid' : '']"
        />
        <div v-if="error.mBin.banco" class="invalid-feedback">
          Seleccione Banco
        </div>
      </b-form-group>

      <b-form-group v-if="mBin.biBanco" label="Tarjeta" label-for="v-tarjeta">
        <v-select
          id="v-tarjeta"
          label="title"
          v-model="mBin.biDescripcion"
          taggable
          :options="tarjetas"
          push-tags
          :clearable="false"
          :class="[error.mBin.descripcion ? 'is-invalid' : '']"
        />
        <div v-if="error.mBin.descripcion" class="invalid-feedback">
          Seleccione tarjeta
        </div>
      </b-form-group>

      <b-form-group label="Comisión" label-for="v-comision">
        <b-form-input
          id="v-comision"
          step="0.01"
          type="number"
          v-model="mBin.biComision"
          placeholder="Comisión"
          :class="[error.mBin.comision ? 'is-invalid' : '']"
        />
        <div v-if="error.mBin.comision" class="invalid-feedback">
          Ingrese comision
        </div>
      </b-form-group>

      <div class="text-right">
        <b-button @click="cancel" class="mr-2" variant="outline-dark">
          Cancelar
        </b-button>
        <b-button @click="saveData" :disabled="saving" variant="primary">
          <b-spinner v-if="saving" />
          <div v-else>Guardar</div>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BButton,
  BSpinner,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { getUserID } from "@/auth/utils";
import { getAuthToken } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BModal,
    BButton,
    vSelect,
    BSpinner,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
  },
  props: {
    bin: {
      type: Object,
      required: true,
    },
    bines: {
      type: Array,
      required: true,
    },
    callback: {
      type: Function,
      required: true,
    },
  },
  watch: {
    bin: function (newVal) {
      if (newVal.biIdbines) {
        this.mBin = newVal;
        this.show = true;
        this.create = false;
      }
    },
  },
  data() {
    return {
      show: false,
      mBin: {
        biIdbines: 0,
      },
      saving: false,
      create: true,
      error: {
        mBin: {
          bin: false,
          banco: false,
          descripcion: false,
          comision: false
        }
      }
    };
  },
  methods: {
    cancel(event) {
      event.preventDefault();
      this.show = false;
    },
    onclickCrear() {
      this.mBin = {
        biBin: "",
        biMarca: 1,
        biBanco: null,
        biActivo: false,
        biIdbines: 0,
        biComision: 1.6,
        biDescripcion: "",
        biUsrcreacion: `${getUserID()}`,
      };
      this.show = true;
      this.create = true;
    },
    validate() {
      this.error.mBin.bin = this.mBin.biBin === ""
      this.error.mBin.banco = this.mBin.biBanco === null
      this.error.mBin.comision =  this.mBin.biComision === null
      if ( this.mBin.biBanco )
        this.error.mBin.descripcion = this.mBin.biDescripcion === ""

      if(
        this.error.mBin.bin ||
        this.error.mBin.banco ||
        this.error.mBin.comision ||
        this.error.mBin.descripcion 
      )       
        return false
      
      return true
    },
    saveData(event) {
      if ( this.validate() ){
        event.preventDefault();
        this.saving = true;
        this.$http
          .post(this.$store.state.app.middlewarePromoPOST, {
            path: this.create ? "/bine/crear" : "/bine/editar",
            body: JSON.stringify({
              ...this.mBin,
              tokenSesion: getAuthToken(),
            }),
          })
          .then((res) => {
            this.saving = false;
            if (typeof res.data === "object" && !res.data.bOk)
              throw new Error(res.data.mensaje);

            this.show = false;
            this.callback();
            this.$toast({
              props: {
                icon: "BellIcon",
                text: `Se creo correctamente el Bin`,
                title: "Exito",
              },
              component: ToastificationContent,
            });
          })
          .catch((e) => {
            this.saving = false;
            this.$toast({
              props: {
                icon: "AlertTriangleIcon",
                text: `Ocurrio un error al crear el Bin (${e.message}).`,
                title: "Error",
                variant: "danger",
              },
              component: ToastificationContent,
            });
          });
      }
    },
  },
  computed: {
    titleModal() {
      if (this.mBin.biIdbines === 0) return "Crear Bin";
      else return "Editar Bin";
    },
    bancos() {
      const bancos = [];
      this.bines.forEach((b) => {
        if (!bancos.includes(b.biBanco)) bancos.push(b.biBanco);
      });
      return bancos;
    },
    tarjetas() {
      const tarjetas = [];
      if (this.mBin.biBanco)
        this.bines
          .filter((b) => b.biBanco === this.mBin.biBanco)
          .forEach((b) => {
            if (!tarjetas.includes(b.biDescripcion))
              tarjetas.push(b.biDescripcion);
          });
      return tarjetas;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
